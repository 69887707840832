<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting" @click="closeAll">
            <button class="close_btn" @click="$emit('update:modelValue', false);"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form class="plan-wizard-form" ref="plan-wizard-form">
                    <div class="content_area">
                        <div class="content_wpr">
                            <div class="section_content w-100 open_area">
                                <div class="section_header center">
                                    <h2 @click="is_edit = true;" v-if="!is_edit">{{plan_name}}</h2>
                                    <h2 v-else><input type="text" v-model="plan_name"><span class="save_btn" v-if="is_edit" @click="is_edit = false;">Save</span></h2>
                                </div>
                                <div class="addblock_section">
                                    <button type="button" class="add_btn pointer ml-auto mr-auto" @click="newWeek = !newWeek;" v-click-outside="closeWeekTypes"><i class="fas fa-plus"></i>Add A Week</button>
                                    <div class="add_types" v-if="newWeek">
                                        <div class="title">Select Week Type</div>
                                        <ul>
                                            <li @click="createNewWeek('Blank')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Blank Week
                                                </div>
                                            </li>
                                            <li @click="createNewWeek('Smart')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Smart Week
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <draggable v-model="weekList" tag="ul" :animation="200" class="week_list" v-if="weekList.length">
                                    <template #item="{ element : week, index : w }">
                                        <li class="move">
                                            <div class="section_header">
                                                <h2 @click="edit_name($event)">{{ week.wk_name }}</h2>
                                                <h2 class="editable"><input type="text" v-model="week.wk_name"><span class="save_btn" @click="edit_name($event)">Save</span></h2>
                                                <div class="week_actions">
                                                    <div type="button" class="week_setting">
                                                        <span @click="weekSetting($event)"><i class="fas fa-cog"></i></span>
                                                        <div class="dropdown_wpr">
                                                            <label :for="`monday_first_${w}`" class="switch_option capsule_btn p-0 mt-2 mb-3 border-0">
                                                                <h5 class="large mb-0">Monday is first</h5>
                                                                <input type="radio" :id="`monday_first_${w}`" value="Monday" v-model="week.first_day" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <label :for="`sunday_first_${w}`" class="switch_option capsule_btn p-0 mt-0 mb-3 border-0">
                                                                <h5 class="large mb-0">Sunday is first</h5>
                                                                <input type="radio" :id="`sunday_first_${w}`" value="Sunday" v-model="week.first_day" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <label :for="`smart_week_${w}`" class="switch_option capsule_btn p-0 mt-0 mb-2 border-0">
                                                                <h5 class="large mb-0">Save as Smart Week</h5>
                                                                <input type="checkbox" :id="`smart_week_${w}`" :true-value="1" :false-value="0" v-model="week.smart_week" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <div class="form_grp py-1 m-0" v-if="week.smart_week == 1">
                                                                <div class="group_item">
                                                                    <label class="input_label">Name</label>
                                                                    <div class="field_wpr has_suffix">
                                                                        <input type="text" v-model="week.wk_name">
                                                                        <span class="suffix" @click="addToSmart(week, week.wk_name)">Save</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="up_down" @click="copySectionWeek(week, w)"><i class="far fa-copy"></i></button>
                                                    <button type="button" class="delete_btn" @click="deleteSectionWeek(w)"><i class="fas fa-trash-alt danger"></i></button>
                                                </div>
                                            </div>
                                            <div class="week_panel">
                                                <ul>
                                                    <li v-for="(item, i) in week.items" :key="i" @click="selectedBox = i; selectedPanel = w;">
                                                        <h5>
                                                            <span @click="daySetting($event)">{{item.title}}<i class="fas fa-chevron-down"></i></span>
                                                            <div class="dropdown_wpr" @click="$event.stopPropagation()">
                                                                <label :for="`workout_day_${i}_${w}`" class="switch_option capsule_btn p-0 mt-2 mb-3 border-0" @click="workoutSetting(item.has_workout)">
                                                                    <h5 class="large mb-0">Workout</h5>
                                                                    <input type="radio" :id="`workout_day_${i}_${w}`" :value="1" v-model="item.has_workout" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                                <div class="form_grp pt-0 pb-3 m-0" v-if="item.has_workout == 1">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Instructions</label>
                                                                        <div class="field_wpr">
                                                                            <input type="text">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <label :for="`rest_day_${i}`" class="switch_option capsule_btn p-0 mt-0 mb-3 border-0">
                                                                    <h5 class="large mb-0">Rest</h5>
                                                                    <input type="radio" :id="`rest_day_${i}`" :value="2" v-model="item.has_workout" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                                <div class="form_grp p-0 m-0" v-if="item.has_workout == 2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Rest Name</label>
                                                                        <div class="field_wpr">
                                                                            <input type="text" v-model="item.info">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp pt-2 pb-0 m-0" v-if="item.has_workout == 2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Information</label>
                                                                        <div class="field_wpr">
                                                                            <input type="text" v-model="item.desc">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </h5>
                                                        <div class="box_item">
                                                            <draggable v-model="item.selected_wt" tag="ol" :animation="200" class="week_list" v-if="item.has_workout == 1">
                                                                <template #item="{ element : wt, index: k}">
                                                                    <li @click="selectedWorkout = k;">
                                                                        <label>
                                                                            <span>{{ wt.title }}</span> <a>{{ wt.period.value }}</a>
                                                                            <div class="quick_info">{{ wt.title}}</div>
                                                                        </label>
                                                                        <i class="fas fa-ellipsis-v" @click="moreOption"></i>
                                                                        <div class="dropdown_wpr small">
                                                                            <ul>
                                                                                <li @click="workoutSetup = true;">Edit</li>
                                                                                <li>
                                                                                    <span @click="copy_to">Copy</span>
                                                                                    <div class="week_days_list">
                                                                                        <label v-for="(wk, d) in week.items" :key="d" :class="{'active' : wk.selected_wt.some(att => att.title === wt.title)}">
                                                                                            <div class="item_wpr" @click="selectedBox = i; selectedPanel = w; copyToFunc($event, wt, d);">
                                                                                                <span class="weekdays">{{wk.title.slice(0, 3)}}</span>
                                                                                                <span class="tick_box"></span>
                                                                                            </div>
                                                                                        </label>
                                                                                        <div class="action_wpr">
                                                                                            <button type="button" class="okay_btn">Okay</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                </template>
                                                            </draggable>
                                                            <p v-else>
                                                                {{item.info}}
                                                                <div class="quick_info">{{ item.desc}}</div>
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </template>
                                </draggable>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <PublicPage :clr="{accent_clr : '#2f7eed', timer_clr : '#f2a31d', btn_clr : '#2f7eed', btn_txt_clr : '#fff', block_bg_clr: '#eff5ff'}" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal workout_modal" v-if="workoutSetup" :class="{active : workoutSetup}">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Workouts <span>Add upto 3 Workouts</span></h1>
                    </div>
                    <button class="close_btn left_out" @click="workoutSetup = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr workout_list">
                        <draggable v-model="weekList[selectedPanel].items[selectedBox].selected_wt" tag="ul" :animation="200" class="workout_card" v-if="weekList[selectedPanel].items[selectedBox].selected_wt.length">
                            <template #item="{ element : workout}">
                                <li>
                                    <div class="wk_item">
                                        <img :src="require(`@/assets/images/thumb/${workout.img}.svg`)" alt="">
                                        <div class="card_details">{{workout.title}}<p>Maecenas fringilla imperdiet hendrerit.</p></div>
                                        <span class="danger" @click="weekList[selectedPanel].items[selectedBox].selected_wt.splice(w, 1)">Remove</span>
                                    </div>
                                    <div class="period">
                                        <div class="form_grp p-0 m-0">
                                            <div class="group_item">
                                                <Multiselect v-model="workout.period.value" v-bind="workout.period"></Multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </draggable>
                        <div class="empty_area" v-else>
                            <h4>Looks like you don't have any Workouts yet.</h4>
                        </div>                   
                    </div>
                    <div class="setting_wpr">
                        <h3 class="sub_header mb-3">Workout List</h3>
                        <draggable v-model="workoutList" tag="ul" :animation="200" class="workout_card">
                            <template #item="{ element : workout}">
                                <li>
                                    <div class="wk_item">
                                        <img :src="require(`@/assets/images/thumb/${workout.img}.svg`)" alt="">
                                        <div class="card_details">{{workout.title}}<p>Maecenas fringilla imperdiet hendrerit.</p></div>
                                        <span @click="selectTag(workout)"><i class="fas fa-plus"></i>Add</span>
                                    </div>
                                    <div class="period">
                                        <div class="form_grp p-0 m-0">
                                            <div class="group_item">
                                                <Multiselect v-model="workout.period.value" v-bind="workout.period"></Multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </draggable>
                    </div>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="workoutSetup = false">Cancel</button>
                    <button type="button" class="btn save_btn">Save</button>
                </div>
            </div>
        </div>
        <div class="modal" v-if="smart_week_setup" :class="{active : smart_week_setup}">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Smart Week <span>Add Smart Week</span></h1>
                    </div>
                    <button class="close_btn left_out" @click="smart_week_setup = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="smart_weeklist">
                        <ul>
                            <li v-for="(week, w) in smartWeeks" :key="w">
                                <h3 class="sub_header">
                                    {{ week.wk_name }}
                                    <button type="button" class="add_btn pointer ml-auto" @click="addSmartWeek(week)"><i class="fas fa-plus"></i>Add</button>
                                </h3>
                                <ul class="week_info">
                                    <li v-for="(item, i) in week.items" :key="i">
                                        <h6>{{item.title}}</h6>
                                        <div class="box_item">
                                            <ol v-if="item.has_workout == 1">
                                                <li v-for="(wt, k) in item.selected_wt" :key="k">{{ wt.title }}</li>
                                            </ol>
                                            <p v-else>{{item.info}}</p>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" v-if="has_edit" :class="{active : has_edit}">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Edit Workout <span>Workout 1</span></h1>
                    </div>
                    <button class="close_btn left_out" @click="has_edit = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="exercise_list">
                        <div class="exercise_item">
                            <div class="exercise_info">
                                <ul>
                                    <li>
                                        <div class="video_area">
                                            <div class="video_wpr">
                                                <div class="video-overlay"></div>
                                                <iframe src="https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div class="info_wpr">
                                            <h4>Lorem Ipsum dolor</h4>
                                            <h6>3 x 30 Reps | 25 LBS</h6>
                                            <h6>30 Sec rest</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="video_area">
                                            <div class="video_wpr">
                                                <div class="video-overlay"></div>
                                                <iframe src="https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div class="info_wpr">
                                            <h4>Lorem Ipsum dolor</h4>
                                            <h6>3 x 30 Reps | 25 LBS</h6>
                                            <h6>30 Sec rest</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="video_area">
                                            <div class="video_wpr">
                                                <div class="video-overlay"></div>
                                                <iframe src="https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div class="info_wpr">
                                            <h4>Lorem Ipsum dolor</h4>
                                            <h6>3 x 30 Reps | 25 LBS</h6>
                                            <h6>30 Sec rest</h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PublicPage from './PublicPage.vue'

import Draggable from 'vuedraggable'
import Multiselect from '@vueform/multiselect'
import { Field, Form, ErrorMessage } from 'vee-validate'

export default {
    name: 'WizardPlan',
    data(){
        return{
            plan_name: 'Untitled Plan',
            is_edit: false,
            newWeek: false,
            weekList:[],
            workoutSetup: false,
            smart_week_setup: false,
            selectedBox: 0,
            selectedPanel: 0,
            selectedWorkout: 0,
            preview: false,
            workoutList:[
                {
                    id: 1,
                    title: 'Workout 1',
                    img: 'default-progress',
                    assigned: 5,
                    period: {
                        mode: 'single',
                        value: 'AM',
                        options: [
                            { value: 'AM', label: 'AM' },
                            { value: 'Mid', label: 'Mid' },
                            { value: 'PM', label: 'PM' }
                        ],
                    },
                    items:[
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                        },
                        {
                            name: 'Dead Hang',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                        },
                        {
                            name: 'Thoracic Bridge',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                        },
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                        },
                    ],
                },
                {
                    id: 2,
                    title: 'Workout 2',
                    img: 'default-progress',
                    assigned: 7,
                    period: {
                        mode: 'single',
                        value: 'AM',
                        options: [
                            { value: 'AM', label: 'AM' },
                            { value: 'Mid', label: 'Mid' },
                            { value: 'PM', label: 'PM' }
                        ],
                    },
                    items:[
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                        },
                        {
                            name: 'Dead Hang',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                        },
                        {
                            name: 'Thoracic Bridge',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                        },
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                        },
                    ],
                },
                {
                    id: 3,
                    title: 'Workout 3',
                    img: 'default-progress',
                    assigned: 12,
                    period: {
                        mode: 'single',
                        value: 'AM',
                        options: [
                            { value: 'AM', label: 'AM' },
                            { value: 'Mid', label: 'Mid' },
                            { value: 'PM', label: 'PM' }
                        ],
                    },
                    items:[
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                        },
                        {
                            name: 'Dead Hang',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                        },
                        {
                            name: 'Thoracic Bridge',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                        },
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                        },
                    ],
                },
            ],
            smartWeeks:[
                {
                    wk_name: 'Smart Week 1',
                    first_day: 'Monday',
                    smart_week: 0,
                    items:[
                        {
                            title: 'Monday',
                            has_workout: 1,
                            selected_wt: [
                                {
                                    id: 1,
                                    title: 'Workout 1',
                                    img: 'default-progress',
                                    assigned: 5,
                                    period: {
                                        mode: 'single',
                                        value: 'AM',
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'Mid', label: 'Mid' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                    },
                                },
                                {
                                    id: 2,
                                    title: 'Workout 2',
                                    img: 'default-progress',
                                    assigned: 7,
                                    period: {
                                        mode: 'single',
                                        value: 'AM',
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'Mid', label: 'Mid' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                    },
                                },
                            ],
                            info: 'Off',
                            desc: 'Maecenas lacus nisi, lobortis nec elit elementum, euismod sollicitudin est. Phasellus convallis blandit convallis.',
                        },
                        {
                            title: 'Tuesday',
                            has_workout: 1,
                            selected_wt: [
                                {
                                    id: 1,
                                    title: 'Workout 1',
                                    img: 'default-progress',
                                    assigned: 5,
                                    period: {
                                        mode: 'single',
                                        value: 'AM',
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'Mid', label: 'Mid' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                    },
                                },
                                {
                                    id: 2,
                                    title: 'Workout 2',
                                    img: 'default-progress',
                                    assigned: 7,
                                    period: {
                                        mode: 'single',
                                        value: 'AM',
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'Mid', label: 'Mid' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                    },
                                },
                            ],
                            info: 'Off',
                            desc: 'Maecenas lacus nisi, lobortis nec elit elementum, euismod sollicitudin est. Phasellus convallis blandit convallis.',
                        },
                        {
                            title: 'Wednesday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'Maecenas lacus nisi, lobortis nec elit elementum, euismod sollicitudin est. Phasellus convallis blandit convallis.',
                        },
                        {
                            title: 'Thursday',
                            has_workout: 1,
                            selected_wt: [
                                {
                                    id: 1,
                                    title: 'Workout 1',
                                    img: 'default-progress',
                                    assigned: 5,
                                    period: {
                                        mode: 'single',
                                        value: 'AM',
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'Mid', label: 'Mid' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                    },
                                },
                                {
                                    id: 2,
                                    title: 'Workout 2',
                                    img: 'default-progress',
                                    assigned: 7,
                                    period: {
                                        mode: 'single',
                                        value: 'AM',
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'Mid', label: 'Mid' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                    },
                                },
                            ],
                            info: 'Off',
                            desc: 'Maecenas lacus nisi, lobortis nec elit elementum, euismod sollicitudin est. Phasellus convallis blandit convallis.',
                        },
                        {
                            title: 'Friday',
                            has_workout: 1,
                            selected_wt: [
                                {
                                    id: 1,
                                    title: 'Workout 1',
                                    img: 'default-progress',
                                    assigned: 5,
                                    period: {
                                        mode: 'single',
                                        value: 'AM',
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'Mid', label: 'Mid' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                    },
                                },
                                {
                                    id: 2,
                                    title: 'Workout 2',
                                    img: 'default-progress',
                                    assigned: 7,
                                    period: {
                                        mode: 'single',
                                        value: 'AM',
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'Mid', label: 'Mid' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                    },
                                },
                            ],
                            info: 'Off',
                            desc: 'Maecenas lacus nisi, lobortis nec elit elementum, euismod sollicitudin est. Phasellus convallis blandit convallis.',
                        },
                        {
                            title: 'Saturday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'Maecenas lacus nisi, lobortis nec elit elementum, euismod sollicitudin est. Phasellus convallis blandit convallis.',
                        },
                        {
                            title: 'Sunday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'Maecenas lacus nisi, lobortis nec elit elementum, euismod sollicitudin est. Phasellus convallis blandit convallis.',
                        },

                    ], 
                }
            ],
            has_edit: false,
        }
    },
    components:{
        PublicPage,
        Draggable,
        Multiselect,
        // Field,
        Form,
        // ErrorMessage,
    },
    props:{
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],

    methods:{
        closeWeekTypes(){
            const vm = this;

            vm.newWeek = false;
        },
        edit_name(ev){
            let parentElm = ev.currentTarget.closest('.section_header');

            if (parentElm.classList.contains('active')) {
                parentElm.classList.remove('active');
            } else {
                parentElm.classList.add('active');
            }
        },
        moreOption(ev){
            ev.stopPropagation();
            
            let parentElm = ev.currentTarget.closest('li').querySelector('.dropdown_wpr');

            if (parentElm.classList.contains('active')) {
                parentElm.classList.remove('active');
            } else {
                parentElm.classList.add('active');
            }
        },
        copy_to(ev){
            ev.stopPropagation();

            let parentElm = ev.currentTarget.closest('li').querySelector('.week_days_list');

            if (parentElm.classList.contains('active')) {
                parentElm.classList.remove('active');
            } else {
                parentElm.classList.add('active');
            }
        },
        weekSetting(ev){
            ev.stopPropagation();

            let parentElm = ev.currentTarget.closest('.week_setting').querySelector('.dropdown_wpr');

            if (parentElm.classList.contains('active')) {
                parentElm.classList.remove('active');
            } else {
                parentElm.classList.add('active');
            }
        },

        daySetting(ev){
            ev.stopPropagation();

            let parentElm = ev.currentTarget.closest('li').querySelector('h5 .dropdown_wpr');

            if (parentElm.classList.contains('active')) {
                parentElm.classList.remove('active');
            } else {
                parentElm.classList.add('active');
            }
        },

        closeAll(){
            let allDropdown = document.querySelectorAll('.dropdown_wpr');
            let allWd_list = document.querySelectorAll('.week_days_list');

            for(let d = 0; d < allDropdown.length; d++){
                allDropdown[d].classList.remove('active');
            }
            for(let l = 0; l < allWd_list.length; l++){
                allWd_list[l].classList.remove('active');
            }
        },

        workoutSetting(el){
            if(el){
                this.workoutSetup = true;
            }
        },

        createNewWeek(type){
            const vm = this;

            if(type == 'Blank'){
                vm.weekList.push({
                    wk_name: 'Foundation Week',
                    first_day: 'Monday',
                    smart_week: 0,
                    items:[
                        {
                            title: 'Monday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'No Information',
                        },
                        {
                            title: 'Tuesday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'No Information',
                        },
                        {
                            title: 'Wednesday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'No Information',
                        },
                        {
                            title: 'Thursday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'No Information',
                        },
                        {
                            title: 'Friday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'No Information',
                        },
                        {
                            title: 'Saturday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'No Information',
                        },
                        {
                            title: 'Sunday',
                            has_workout: 2,
                            selected_wt: [],
                            info: 'Off',
                            desc: 'No Information',
                        },

                    ],
                });
            } else{
                vm.smart_week_setup = true;
            }
        },

        copySectionWeek(el, i){
            const vm = this;

            vm.weekList.splice((i + 1), 0, el);
        },

        deleteSectionWeek(el){
            const vm = this;

            vm.weekList.splice(el, 1);
        },

        selectTag(item){
            const vm = this;

            if(vm.weekList[vm.selectedPanel].items[vm.selectedBox].selected_wt.length < 3){
                vm.weekList[vm.selectedPanel].items[vm.selectedBox].selected_wt.push(item);
            }
        },

        copyToFunc(ev, item, day){

            ev.stopPropagation();

            const vm = this;
            const has_item = vm.weekList[vm.selectedPanel].items[day].selected_wt.findIndex((att) => att.title === item.title);
            
            if(has_item == -1){
                vm.weekList[vm.selectedPanel].items[day].selected_wt.push(item);
                vm.weekList[vm.selectedPanel].items[day].has_workout = 1;

            } else{
                vm.weekList[vm.selectedPanel].items[day].selected_wt.splice(has_item, 1);
            }

        },

        addSmartWeek(smart_item){
            const vm = this;

            vm.weekList.push(smart_item);
        },

        addToSmart(item){
            const vm = this;

            vm.smartWeeks.push(item);
        }
    }
}
</script>

<style scoped>
.section_header h2{
    display: flex;
    align-items: center;
    gap: 10px;
}
.section_header.active h2:not(.editable), .section_header h2.editable{
    display: none;
}
.section_header.active h2.editable{
    display: flex;
}
.section_header h2 input{
    font-size: 16px;
    line-height: 20px;
    background: transparent;
    width: 200px;
    background: #f0f5ff;
    padding: 10px 15px;
    border-radius: 5px;
}
.section_header h2 .save_btn{
    padding: 4px 10px;
    background: #2f7eed;
    color: #fff;
    border-radius: 3px;
    font-size: 9px;
    line-height: 11px;
    text-transform: uppercase;
    cursor: pointer;
}
    .plan-wizard-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .section_header.center{
        justify-content: center;
        margin-bottom: 20px;
    }
    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }
    .preview_content{
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }
    .preview_content.show{
        margin-right: 0;
    }
    .preview_content .close_btn{
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }
    .week_setting{
        position: relative;
    }
    .addblock_section{
        position: relative;
        width: 100%;
        padding: 0 10px 20px 10px;
    }
    .addblock_section .add_types {
        max-width: 252px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0,0,0,.1);
        text-align: center;
        z-index: 2;
    }
    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }
    .addblock_section .add_types ul{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px 20px;
    }
    .addblock_section .add_types ul li{
        flex: 0 1 100px;
    }
    .addblock_section .add_types ul li .card_item{
        padding: 15px 5px;
    }
    .addblock_section .add_types .title{
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }
    .week_list .week_actions{
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .week_list .week_actions button{
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }
    .week_list .week_actions button.up_down{
        color: #999;
    }
    .week_list .week_actions .week_setting{
        position: relative;
        cursor: pointer;
    }
    .week_list .week_actions .week_setting i{
        font-size: 15px;
        color: #999;
    }
    .week_list .week_actions .week_setting .dropdown_wpr{
        min-width: 200px;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
        transform: none;
    }
    .week_list .week_actions .week_setting .dropdown_wpr:before{
        display: none;
    }
    .week_list .week_actions .week_setting .dropdown_wpr ul li{
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }
    .week_list .week_actions .week_setting .dropdown_wpr .group_item .input_label{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr{
        background: #fff;
    }
    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr input{
        height: 30px;
        min-height: 30px;
        font-size: 11px;
        padding: 0 10px;
    }
    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr.has_suffix{
        padding-right: 40px;
    }
    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr.has_suffix .suffix{
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }
    .week_list .week_actions .week_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown){
        min-width: 100%;
    }
    .week_list .week_actions .week_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li){
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }
    .week_list li .week_panel{
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 20px 30px 30px 30px;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
    }
    /* .week_list li .week_panel.active{
        border: 1px solid #c8dfff;
        background: #fbfbfb;
    } */
    .week_list li .week_panel ul{
        display: flex;
        flex-wrap: wrap;
    }
    .week_list li .week_panel > ul > li{
        width: 14.28%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .week_list li .week_panel ul li h5{
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
    }
    .week_list li .week_panel ul li h5 i{
        font-size: 9px;
        color: #999;
        margin-left: 10px;
    }
    .week_list li .week_panel ul li .box_item{
        height: 150px;
        width: 100%;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #f5f5f5;
    }
    .week_list li .week_panel ul li .box_item p{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        position: relative;
        cursor: pointer;
    }
    .week_list li .week_panel ul li .box_item ol{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .week_list li .week_panel ul li .box_item ol > li{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: top;
        gap:5px;
        position:relative;
    }
    .week_list li .week_panel ul li .box_item ol > li > label{
        background: #f5f5f5;
        border-radius: 12px;
        display: flex;
        position: relative;
    }
    .week_list li .week_panel ul li .box_item ol > li > label span{
        padding: 4px 15px;
        font-size: 11px;
        line-height: 14px;
    }
    .week_list li .week_panel ul li .box_item ol > li > label a{
        background: #dbdbdb;
        color: #121525;
        padding: 3px 8px;
        font-size: 9px;
        line-height: 12px;
        border-radius: 0 12px 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .week_list li .week_panel ul li .box_item ol li i{
        padding: 5px 3px;
        color: #5a5a5a;
        cursor:pointer;
    }
    .week_list .box_item ol li .dropdown_wpr.small{
        min-width: 60px;
        padding: 0;
        left: 100%;
        right: auto;
    }
    .week_list .box_item ol li .dropdown_wpr ul li{
        border: 0;
    }
    .week_list .dropdown_wpr{
        min-width: 150px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }
    .week_list .dropdown_wpr:before{
        display: none;
    }
    .week_list .dropdown_wpr .group_item .input_label{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 6px;
        display: block;
    }
    .week_list .dropdown_wpr .group_item .field_wpr input{
        height: 30px;
        font-size: 11px;
        padding: 0 10px;
    }
    .workout_card li{
        display: flex;
        justify-content: flex-start;
        /* align-items: flex-start; */
        gap: 5px;
        margin-bottom: 20px;
    }
    .workout_card li .wk_item{
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        position: relative;
    }
    .workout_card li .period{
        flex: 0 0 55px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
    }

    .workout_card li .period .form_grp :deep(.multiselect){
        /* min-height: 40px; */
        min-height: 66px;
    }
    .workout_card li .period :deep(.multiselect-single-label){
        padding: 0 15px 0 5px;
    }
    .workout_card li .period :deep(.multiselect-caret){
        margin-right: 0;
    }
    .workout_card li .period :deep(.multiselect-single-label-text){
        font-size: 11px;
    }
    .workout_card li .wk_item img {
        width: 100px;
        height: auto;
        object-fit: cover;
    }
    .workout_card .card_details{
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }
    .workout_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }
    .workout_card li span {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }
    .workout_card li span i{
        font-size: 9px;
        margin-right: 5px;
    }

    .modal.workout_modal .modal_body{
        padding-top: 0;
    }
    .workout_list{
        background: #f5f5f5;
        border-bottom: 1px solid #eaeaea;
        margin: 0 -45px;
        padding: 40px 45px 20px 45px;
    }
    .smart_weeklist .add_btn{
        font-size: 13px;
        line-height: 16px;
    }
    .smart_weeklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }
    .smart_weeklist > ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .smart_weeklist > ul .week_info{
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 5px 10px 20px 10px;
    }
    .smart_weeklist > ul .week_info > li{
        padding: 5px;
        width: 25%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .smart_weeklist > ul .week_info li h6{
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }
    .smart_weeklist > ul .week_info li .box_item{
        flex-grow: 1;
        min-height: 70px;
        background: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }
    .smart_weeklist > ul .week_info li .box_item p{
        font-size: 10px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-top: 3px;
    }
    .smart_weeklist > ul .week_info li .box_item ol{
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .smart_weeklist > ul .week_info li .box_item ol li{
        list-style-type: inherit;
        font-size: 9px;
        line-height: 12px;
        padding: 3px 8px;
        font-weight: 400;
        color: #5a5a5a;
        background: #fff;
        border-radius: 9px;
    }
    .exercise_list{
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    /* .exercise_item .ex_title{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
    } */
    .exercise_item .exercise_info ul{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .exercise_item .exercise_info ul li{
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 6px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        cursor: pointer;
    }
    .exercise_item .exercise_info ul li .video_area{
        width: 45%;
    }
    .video_wpr{
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 4px;
        overflow: hidden;
    }
    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
    .exercise_item .exercise_info ul li .info_wpr{
        width: calc(55% - 15px);
    }
    .exercise_item .exercise_info ul li .info_wpr h4{
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .exercise_item .exercise_info ul li .info_wpr h6{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 5px 0;
    }

    .week_days_list{
        background: #fff;
        position: absolute;
        width: 250px;
        left: 50%;
        top: 100%;
        right: auto;
        transform: translateX(-50%);
        box-shadow: 5px 50px 50px rgba(0,0,0,.1);
        border-radius: 8px;
        padding: 10px;
        z-index: 4;
        display: none;
        justify-content: center;
        flex-wrap: wrap;
        gap: 3px;
        margin-top: 10px;
    }
    .week_days_list.active{
        display: flex;
    }
    .week_days_list label .item_wpr{
        width: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 6px 4px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }
    .week_days_list label span{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .week_days_list label .tick_box{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #cdcdcd;
        position: relative;
        margin-top: 7px;
    }
    .week_days_list label .tick_box:after{
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .week_days_list label.active .item_wpr{
        background: #fbfbfb;
        border: 1px solid #d4d4d4;
    }
    .week_days_list label.active .item_wpr .tick_box:after{
        transform: scale(1);
    }
    .week_days_list .action_wpr{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }
    .week_days_list .action_wpr .okay_btn{
        padding: 5px 15px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #fff;
        background: #2f7eed;
        border-radius: 3px;
        cursor: pointer;
    }
    .empty_area{
        border-radius: 10px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .empty_area h4 {
        font-family: 'Architects Daughter', cursive;
        font-size: 16px;
        line-height: 35px;
        font-weight: 300;
        color: #999;
        max-width: 100%;
        position: relative;
        padding-bottom: 20px;
    }
    .quick_info{
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        width: 180px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: auto;
        bottom: 100%;
        transform: translateX(-50%);
        display: none;
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }
    .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }
    .week_list li .week_panel ul li .box_item p:hover .quick_info, .week_list li .week_panel ul li .box_item ol > li > label:hover .quick_info{
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }


    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p,
    .cell .sms_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }
    :deep(.cell .dashboard_footer) {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }
</style>